export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return 'unknown'
}

export const isIos = () => getMobileOperatingSystem() === 'iOS'

export const isFastConnection = navigator.connection?.downlink > 0.5
